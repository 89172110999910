import { useState, useEffect, useCallback } from 'react';

export default function useTouch() {

  const [canRender, setRender] = useState(false);
  const [isTouch, setIsTouch] = useState(false);

  const touchListeners = useCallback(() => {

    const handleTouchStart = () => {
      if (!isTouch) {
        setIsTouch(true)
      }
    }

    // Check in navigator first...
    if ('ontouchstart' in window) {
      setIsTouch(true)
    }
    // Double-check on touch event...
    window.addEventListener('touchstart', handleTouchStart)
    // Let components with touch dependencies know that the touch test is done...
    setRender(true)
    // Cleanup...
    return () => {
      window.removeEventListener('touchstart', handleTouchStart, false)
    }
  }, [setIsTouch, setRender, isTouch])

  useEffect(touchListeners, [])

  return [isTouch, canRender]
}
