// Dependencies
import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import moment from 'moment'
// Hooks
import useBottomOfPage from '../hooks/useBottomOfPage';
import useTouch from '../hooks/useTouch';
// Components
import Clicker from '../components/clicker'
import Layout from '../components/layout'
import Markdown from '../components/markdown'
import Image from '../components/image'
import Swiper from '../components/swiper'
import Video from '../components/video'

function findHexCode(string) {
  const colors = [];

  string.replace(/[0-9A-F]{6}/gi, function(color) {
    colors.push(color);
  });

  return colors[0] ? `#${colors[0]}` : null
}

const Gallery = ({ assets }) => {

  const [isTouch] = useTouch();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [caption, setCaption] = useState(assets[0].description);
  const [showClicker, setShowClicker] = useState(false);
  const [cursorColor, setCursorColor] = useState("000000");

  const handlers = {
    onMouseEnter: () => setShowClicker(true),
    onMouseLeave: () => setShowClicker(false)
  };

  return (
    <section {...handlers}>
      <div className="page-gallery contain">
        <Swiper
          onSlideUpdate={(slideIndex) => {
            setCurrentSlideIndex(slideIndex)
            setCaption(assets[slideIndex].description)
            const color = findHexCode(assets[slideIndex].title);
            setCursorColor(color || "#000000");
          }}
          currentSlideIndex={currentSlideIndex}
          setCurrentSlideIndex={setCurrentSlideIndex}
        >
          {assets.map((asset, index) => {
            return asset.file.contentType === "video/mp4" ? (
              <Video src={asset.file.url} isTouch={isTouch} key={`video-${index}`} />
            ) : (
              <Image {...asset} key={`image-${index}`} />
            )
          })}
        </Swiper>
        {showClicker && <Clicker color={cursorColor}>{`${currentSlideIndex + 1} of ${assets.length}`}</Clicker>}
      </div>
      <p className="page-gallery-captions p2">
        <span className="touch">{`(${currentSlideIndex + 1} of ${assets.length})`} </span>
        <span>{`${caption ? caption : "\u00A0"}`}</span>
      </p>
    </section>
  )
}

const Grid = ({ id, assets }) => {

  const [isTouch] = useTouch();

  return (
    <section className="page-grid">
      {assets.map((asset, index) => {
        const cursorColor = findHexCode(asset.title);

        return (
          <div className="page-grid-item" key={`${id}-asset-${index}`}>
            {asset.file?.contentType === "video/mp4" ? (
              <Video src={asset.file.url} hasCursor cursorColor={cursorColor} isTouch={isTouch} />
            ) : (
              <Image {...asset} />
            )}
            {asset.description && (
              <p className="p2">{asset.description}</p>
            )}
          </div>
        )
      })}
    </section>
  )
}

const Copy = ({ copy }) => {

  return (
    <section className="page-copy">
      <Markdown field={copy} />
    </section>
  )
}

const Wrapper = ({ slug, signOff, sections, setShowAboutButton, showIntro }) => {

  const year = moment().format('YYYY');

  const [isBottom] = useBottomOfPage();

  useEffect(useCallback(() => {
    if (isBottom) {
      setShowAboutButton(true)
    }
  }, [isBottom, setShowAboutButton]), [isBottom]);

  return (
    <article className={`${!showIntro ? 'show' : ''}`}>
      {sections && sections.map((section, index) => {

        const id = `${slug}-section-${index}`;
        const isGallery = section.layout && section.layout[0] === "gallery";

        return section.assets ? (
          isGallery ? (
            <Gallery {...section} id={id} key={id} />
          ) : (
            <Grid {...section} id={id} key={id} />
          )
        ) : section.copy ? (
          <Copy {...section} id={id} key={id} />
        ) : null;
      })}

      {/* <footer>
        <div className="p2">
          <span style={{ marginRight: '3em' }}>{`A.O.N. © ${year}`}</span>
          {signOff && <span>{signOff}</span>}
        </div>
      </footer> */}
    </article>
  )
}

const Page = ({ data }) => {

  const { introText, colours } = data.contentfulPage;

  return (
    <Layout 
			hasIntro={introText ? true : false} 
			introText={introText} 
			splashIntroText={introText} 
			introTimer={5000}
			css={`
				html {
					background: ${colours ? colours.backgroundColour : "#FFFFFF"};
					color: ${colours ? colours.textColour : "#000000"};
				}
				.cookie-notice {
					color: ${colours ? colours.textColour : "#000000"};
				}
			`}
		>
      {(setShowAboutButton, showIntro) => 
				<Wrapper 
					{...data.contentfulPage} 
					setShowAboutButton={setShowAboutButton} 
					showIntro={showIntro} 
				/>
			}
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired
}

export default Page

export const pageQuery = graphql`
  query pageTemplateQuery($slug: String!) {
    contentfulPage(slug: {eq: $slug}) {
      title
      slug
      introText
      signOff
      ## Colours (theme)
      colours {
        textColour
        backgroundColour
      }
      # Sections...
      sections {
        ## Asset(s)
        assets {
          title
          description
          ## File
          file {
            contentType
            url
          }
          fluid(maxWidth: 1200, quality: 70) {
            ...GatsbyContentfulFluid_noBase64
          }
        }
        ## Copy
        copy {
          childMarkdownRemark {
            html
          }
        }
        ## Grid or Gallery
        layout
      }
    }
  }
`
