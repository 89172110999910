import { useState, useCallback } from 'react';
import useEventListener from './useEventListener';

export default function useBottomOfPage() {

  const MARGIN = 10;
  const [isBottom, setBottom] = useState(false);

  const handler = useCallback((event) => {
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;
    const documentHeight = Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight
    );
    if (!isBottom && (scrollTop + windowHeight >= documentHeight - MARGIN)) {
      setBottom(true)
    } else if (isBottom && (scrollTop + windowHeight < documentHeight - MARGIN)) {
      setBottom(false)
    }
  }, [isBottom, setBottom]);

  useEventListener('scroll', handler)

  return [isBottom]
}
