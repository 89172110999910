import React, { useEffect, useRef, useCallback } from 'react'
import { useInView } from 'react-intersection-observer';
// Hooks
import useTouch from "../hooks/useTouch";

const Slide = ({ onSlideUpdate, slideIndex, children }) => {

  const [ref, inView] = useInView({
    threshold: 0.5
  });

  const handleSlideChange = useCallback(() => {
    if (inView) {
      onSlideUpdate(slideIndex)
    }
  }, [inView, onSlideUpdate, slideIndex]);

  useEffect(handleSlideChange, [inView]);

  return (
    <div className="swiper-slide" ref={ref}>
      {children}
    </div>
  )
}

const Swiper = ({ onSlideUpdate, currentSlideIndex, setCurrentSlideIndex, children }) => {

  const container = useRef();
  const wrapper = useRef();

  const [isTouch] = useTouch();

  const next = () => {
    if (currentSlideIndex + 1 < children.length) {
      wrapper.current.scrollLeft = wrapper.current.scrollLeft + container.current.offsetWidth;
    } else {
      wrapper.current.scrollLeft = 0
    }
  }

  const prev = () => {
    if (currentSlideIndex > 0) {
      wrapper.current.scrollLeft = wrapper.current.scrollLeft - container.current.offsetWidth;
    } else {
      wrapper.current.scrollLeft = container.current.offsetWidth * children.length;
    }
  }

  const handleClick = e => {
    if (e.clientX < container.current.offsetWidth / 2) {
      prev()
    } else {
      next()
    }
  }

  return (
    <div className="swiper-container" ref={container} onClick={!isTouch ? handleClick : null}>
      <div className="swiper-wrapper" ref={wrapper}>
        {React.Children.map(children, (child, index) => (
          <Slide slideIndex={index} onSlideUpdate={onSlideUpdate} key={`slide-${index}`}>
            {child}
          </Slide>
        ))}
      </div>
    </div>
  )
}

export default Swiper
